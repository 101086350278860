<template>
  <v-row
    style="margin-left: 200px; text-align: center; margin-top: 10%"
    v-if="duration > 0"
  >
    <p
      style="
        font-size: 90px;
        color: #7297ff;
        text-align: center;
        margin-right: 40px;
      "
    >
      {{ $t("Your Exam Will Start In") }}
    </p>
    <circular-count-down-timer
      :initial-value="duration"
      :size="300"
      :stroke-width="2"
      :seconds-stroke-color="'#7297FF'"
      :minutes-stroke-color="'#7297FF'"
      :hours-stroke-color="'#7297FF'"
      :underneath-stroke-color="'lightgrey'"
      :seconds-fill-color="'#ffffff'"
      :minutes-fill-color="'#ffffff'"
      :hours-fill-color="'#ffffff'"
      :hour-label="'hours'"
      :minute-label="'minutes'"
      :second-label="'seconds'"
      :show-second="true"
      :show-minute="true"
      :show-hour="true"
      :show-negatives="true"
      :notify-every="'minute'"
      @finish="getExam"
    ></circular-count-down-timer>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  name: "examCorrection",
  data() {
    return {
      duration: 0,
    };
  },
  methods: {
    getExam() {
      this.$router.push(
        {
          path:
            "/studentViewOnlineExam/" + this.$router.currentRoute.params.examId,
        },
        () => {}
      );
    },
    getExamRemainigTime() {
      //   console.log("k");
      axios
        .get(
          this.getApiUrl +
            "/exams/getExamRemainingSeconds/" +
            this.$router.currentRoute.params.examId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.duration = response.data.data;
          if (this.duration == 0) {
            this.getExam();
          }
        });
    },
  },
  mounted() {
    this.getExamRemainigTime();
  },
};
</script>
